.options {
  display: flex;
  flex: 1;
  flex-direction: column;
  > .row {
    display: flex;
    flex: 1;
    flex-direction: row;
    & + .row {
      margin-top: 5px;
    }
  }
}
.root {
    background: #2479b3;
    border: 0;
    border-radius: 3px;
    color: #fff;
    cursor: pointer;
    font-family: Montserrat;
    font-weight: 700;
    font-size: 13px;
    margin: 5px 5px 5px 0;
    width: 110px;
    height: 50px;
    line-height: 1.5;
    text-align: center;
    
    @media (min-width: 768px) {
        width: 150px;
    }

    &:hover {
        background-color: lighten(#2479b3, 3%);
      }
    
    &:active {
        background-color: darken(#2479b3, 3%);
    }

    &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }

    &.exception {
        background: transparent;
        border: 2px dashed #f26161;
        border-radius: 3px;
        color: #f26161;
    }

    .buttonContent {
        /*width: 100%;*/
        /*height: 50px;*/
        display: flex;
    }

    .buttonImg {
        height: 30px;
        margin: auto;
        display: none;
        @media (min-width: 768px) {
            display: block;
        }


    }

    .buttonText {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: auto;
    }

}
.imageFrameContainer {
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px 20px;
  margin-top: 10px;
  margin-bottom: 10px; }

.imageHeader {
  margin-bottom: 5px; }

.waybillImages {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  box-shadow: 0 2px 10px 0 rgba(194, 198, 202, 0.5), 0 2px 0 0 rgba(194, 198, 202, 0.5); }

.sendWaybillsButtonContainer {
  padding-bottom: 10px; }

.sendWaybillsButton {
  font-size: 14px; }

.imageContainer {
  background: black;
  border-radius: 8px;
  height: 360px;
  width: 300px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center; }

.statusContainer {
  height: 48px;
  padding: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center; }

.statusContainerHidden {
  height: 48px;
  padding: 4px;
  content-visibility: hidden; }

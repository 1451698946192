.root {
  @media (min-width: 768px) {
    width: 360px;
  }

  header {
    font: 300 28px/0.86 Montserrat;
    margin: 0 0 40px;
  }

  > div {
    width: 100%;
  }

  .buttonMargin {
    margin-top: 40px;
  }
}

.label {
  display: block;
  font-family: Montserrat;
  font-size: 13px;
  font-weight: 300;
  letter-spacing: 0.5px;
  line-height: 1.5;
  margin-bottom: -10px;
  text-align: left;
  text-transform: uppercase;
}

.field {
  text-align: left;

  > div {
    padding-left: 25px;
    padding-right: 25px;
    height: 60px;
    display: inline-flex;
    align-items: center;
  }

  + .label {
    margin-top: 20px;
  }
}

.extraLabel {
  display: block;
  font-family: Montserrat;
  font-size: 13px;
  font-weight: 300;
  letter-spacing: 0.5px;
  line-height: 1.5;
  margin-bottom: -10px;
  text-align: left;
  text-transform: uppercase;
  margin-top: 20px;
}

.textfieldLookalike {
  width: 100%;
  position: relative;
  &:before {
    left: 0;
    right: 0;
    bottom: 0;
    content: "\00a0";
    position: absolute;
    transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    pointer-events: none;
    border-bottom-style: dotted;
  }
}

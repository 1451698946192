.root {
  color: #7a868d;

  @media (min-width: 768px) {
    height: 50px;
    display: flex;
    width: calc(100% - 70px);
    max-width: calc(100vw - 350px);
  }

  > span {
    box-shadow: 0 1px 0 0 #dfe3e5;
    display: block;
    line-height: 40px;

    &:last-of-type {
      box-shadow: none;
    }

    @media (min-width: 768px) {
      box-shadow: none;
      height: 100%;
      line-height: inherit;
      display: flex;
      align-items: center;
    }
  }
}

.id {
  background: url(../assets/order--lightgrey.svg) 2px 50% no-repeat;
  letter-spacing: 1px;
  min-width: 140px;
  padding-left: 30px;
  padding-right: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media (min-width: 768px) {
    max-width: 50%;
  }

  span {
    display: inline-block;
    margin-right: 10px;
    max-width: calc(100% - 120px);
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;

    @media (min-width: 768px) {
      max-width: 100%;
    }
  }
  @media (min-width: 768px) {
    margin-right: 17px;
    background-position: 0 50%;
    box-shadow: 1px 0 0 0 #dfe3e5 !important;
    padding-left: 23px;
  }
}

.commodity {
  background: url(../assets/cargo--sm--lightgrey.svg) 0 50% no-repeat;
  padding-left: 30px;
  white-space: nowrap;

  @media (min-width: 768px) {
    padding-left: 35px;
  }
}

.root {
    margin: 10px;
    border-radius: 3px;
    max-width: 90%;

  & > button {
    opacity: 0;
    width: 0px;
    margin: 0px;
    font-family: Roboto;
    font-weight: 700;
    padding: 0 10px 0 10px;
    text-align: center;
  }

  &.primary {
    width: 90%;
    height: 50px;
    font-weight: 700;
    font-size: 1.1em;

    // Media based on Modal media points
    @media (min-width: 768px) {
      width: 40%;
    }

    & > button {
      margin: auto;
      line-height: 50px;
    }

  }

  &.secondary {
    width: 40%;
    height: 50px;
    font-weight: 700;
    font-size: 0.5em;

    // This breakpoint is a small hack to handle long strings for the exceptions buttons
    @media (min-width: 380px) {
      font-size: 0.7em;
    }

    // Media based on Modal media points
    @media (min-width: 768px) {
      width: 40%;
      font-size: 0.8em;
    }
    
    & > button {
      margin: auto;
    }

  }

  &.checked {
    color: #fff;
    background: #f26161;
    border: 2px solid #f26161;
    &:hover {
      border: 2px solid darken(#f26161, 3%);
      background-color: darken(#f26161, 3%);
    }
  }

  &.unchecked {
    color: #f26161;
    background: transparent;
    border: 2px solid #f26161;
    &:hover {
      color: #fff;
      border: 2px solid darken(#f26161, 6%);
      background-color: darken(#f26161, 6%);
    }
  }

  &.disabled {
    color: #7a868d;
    background: lighten(#7a868d, 30%);
    border: 2px solid #7a868d;
    &:hover {
      color: #7a868d;
      background: lighten(#7a868d, 30%);
      border: 2px solid #7a868d;
    }
  }
}
.root {
  margin: 70px auto;
  max-width: 600px;
  text-align: center;

  h2, h3 {
    font-family: Montserrat;
    font-weight: 300;
    line-height: 32px;
  }
  h2 {
    font-size: 28px;
    letter-spacing: -.5px;
    margin: 0 0 5px;
  }
  h3 {
    color: rgba(#45555F, .7);
    font-size: 22px;
    letter-spacing: -.4px;
    margin: 0 0 65px;
  }
  input {
    text-transform: uppercase;
  }
  button {
    margin-top: 30px;
  }
}
.root {
  font-family: Montserrat;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

svg {
  vertical-align: middle;
  margin-right: 0.5em;
  transform: scale(0.8);
  top: -0.1em;
  position: relative;
}

.back {
  color: #45555f;
  cursor: pointer;
  display: inline-block;
  font-size: 18px;
  line-height: 32px;
  margin: 20px 25px 0;
  padding-left: 25px;
}

.root {
  padding-top: 30px;
  position: absolute;
  width: 100%;
  // min-height: 100%;
  top: 0;
  left: 0;
  background: #fff;

  @media (min-width: 1024px) {
    display: flex;
    justify-content: center;
  }

  > div {
    background-size: 100px auto;
    background-position: 50% 35px;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    padding: 35px 25px 30px;
    text-align: center;

    @media (min-width: 1024px) {
      background-size: auto;
      background-position: 50% 65px;
      height: calc(100vh - 92px);
      padding: 65px 45px 75px;
      width: 505px;
    }

    &.mode1 {
      background-image: url(../components/auth/assets/login-mode-1.svg);
    }
    &.mode2 {
      background-image: url(../components/auth/assets/login-mode-2.svg);

      @media (min-width: 1024px) {
        background-position: 50% 70px;
      }
    }

    h3 {
      font: 300 28px/1.14 Montserrat;
      letter-spacing: -0.5px;
      margin: 135px 0 15px;

      @media (min-width: 1024px) {
        margin: 235px 0 20px;
      }
    }
    p {
      flex: 1 1;
      font-size: 14px;
      line-height: 1.5;

      @media (min-width: 1024px) {
        font-size: 18px;
        line-height: 1.28;
      }
    }

    button {
      @media (max-width: 1023px) {
        font-size: 16px;
        font-weight: 300;
        text-transform: none;
        width: 100%;
      }
    }

    &:nth-child(2) {
      color: #45555f;
    }
  }
}

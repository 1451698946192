.root {
  width: 100%;
  padding: 2px 20px;
  text-align: right;
  background-color: #34404a;
  color: #f9f9f9;
  font-size: 14px;
  height: 25px;
  display: flex;
  position: sticky;
  top: 0;
  z-index: 999;
  flex: 0!important;

  @media (max-width: 767px) {
    font-size: 12px;
    height: 20px;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  span {
    display: inline-block;
    flex: 1 1;
    opacity: 0.7;

    &:first-child {
      text-align: left;
    }
  }
  .ApplicationVersion {
    position: absolute;
    opacity: 0.7;
    left: 45%;
  }

  :global span.location {
    font-size: 16px;
    line-height: 1;
    opacity: 1;
    text-align: center;
  }
}

.offline {
  font-weight: bold;
  background-color: #f26161;
}

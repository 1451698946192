.container {
  width: 100%;
  height: 100%;
  top: 10%;
  left: 10%;
}

.sigContainer {
  width: 300px;
  height: 150px;
  margin: 0 auto;
  background-color: #fff;
  border:1px solid black;
  margin-bottom: 5px;
}

.sigPad {
  width: 100%;
  height: 100%;
}

.buttons {
  width: 50%;
  height: 30px;
}

.sigImage {
  margin-top: 5px;
  background-size: 200px 100px;
  width: 200px;
  height: 100px;
  background-color: white;
}

.back {
  display: inline-block;
  margin: 30px 25px;
  padding-left: 25px;
  position: absolute;
  top: 0;
  left: 0;
}

.root {
  margin: 70px auto;
  max-width: 600px;
  text-align: center;

  .spotLink {
    color: #45555f;
    text-decoration: underline;
    margin: 30px auto;
    cursor: pointer;
  }

  h2, h3 {
    font-family: Montserrat;
    font-weight: 300;
    line-height: 32px;
  }
  h2 {
    font-size: 28px;
    letter-spacing: -.5px;
    margin: 0 0 5px;
  }
  h3 {
    color: rgba(#45555F, .7);
    font-size: 22px;
    letter-spacing: -.4px;
    margin: 0 0 65px;
  }
  input {
    text-transform: uppercase;
  }

  .vehicle {
    background: url(../assets/chevron-right-grey.svg) calc(100% - 30px) 50%/11px 20px no-repeat;
    cursor: pointer;
    font-family: "Roboto Mono";
    font-size: 24px;
    letter-spacing: 1px;
    padding: 15px 30px;
    text-align: left;

    + .vehicle {
      border-top: 1px solid rgba(#C2C6CA, .5);
    }
  }
}
:global span.routeAmount {
  display: inline-block;
  vertical-align: top;
  margin-top: 2px;
  text-align: center;
  position: absolute;
  margin-left: 5px;
  width: 16px;
  height: 16px;
  line-height: 16px;
  border-radius: 50%;
  background: #45555F;
  color: #fff;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 700;
}

:global a.active span.routeAmount {
  background: #57A50B;
}
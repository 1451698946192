.root {
    padding: 10px 20px;
    // position: relative;
    // margin-bottom: 10px;

    // @media (min-width: 1024px) {
    //   padding-right: 90px;
    // }
  }

  .addresses {
    margin-top: 0px;
    margin-bottom: 5px;
    > div {
      &.destination {
        > div {
          font-size: 14px;
          min-height: 20px;
          padding-top: 3px;
          padding-bottom: 3px;
          padding-left: 35px;
        }

        .address {
          background: url(../../../../assets/arrow-right-grey.svg) 0 6px/16px 14px
            no-repeat;
        }
        .customer {
          background: url(../../assets/user--sm.svg) 0 4px/18px 18px no-repeat;
          color: #45555f;
        }
        .person {
          @media (max-width: 1023px) {
            display: none;
          }
          background: url(../../assets/user--sm.svg) 0 4px/18px 18px no-repeat;
          color: #45555f;
        }
        .waybill {
          background: url(../../assets/order--lightgrey.svg) 0 4px/18px 18px
            no-repeat;
          color: #45555f;
        }
      }
    }
  }

  .dataHeader {
    font-size: 14px;
    padding: 0px 20px;

    @media (max-width: 1023px) {
      display: none;
    }
  }

  .dataHeader span {
    display: block;

    @media (min-width: 1024px) {
      display: inline-block;
    }
  }

  .dataTable {
    font-size: 18px;
    font-weight: 700;
    // margin-bottom: 5px;

    @media (min-width: 1024px) {
      // margin-bottom: 10px;
    }
  }

  .dataTable span {
    display: block;
    vertical-align: top;

    @media (min-width: 1024px) {
      display: inline-block;
    }
  }

  @mixin dataFieldWidth($width) {
    width: 100% * $width/700;
  }

  @mixin dataFieldBefore {
    color: rgba(#45555f, 0.7);
    display: inline-block;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    width: 80px;
    margin-top: -4px;
  }

  .dataProductName {
    @media (max-width: 1023px) {
      &:before {
        content: "Tuotenimi";
        @include dataFieldBefore();
      }
    }
    @media (min-width: 1024px) {
      @include dataFieldWidth(200);
    }
  }

  .dataAmountLoaded {
    @media (max-width: 1023px) {
      &:before {
        content: "Toteutunut";
        @include dataFieldBefore();
      }
    }
    @media (min-width: 1024px) {
      @include dataFieldWidth(100);
    }
  }

  .dataAmount {
    @media (max-width: 1023px) {
      &:before {
        content: "Tilattu";
        @include dataFieldBefore();
      }
    }
    @media (min-width: 1024px) {
      @include dataFieldWidth(100);
    }
  }

  .dataWeight {
    @media (max-width: 1023px) {
      &:before {
        content: "Kilot";
        @include dataFieldBefore();
      }
    }
    @media (min-width: 1024px) {
      @include dataFieldWidth(100);
    }
  }

  .dataItems {
    @media (max-width: 1023px) {
      &:before {
        content: "Kollit";
        @include dataFieldBefore();
      }
    }
    @media (min-width: 1024px) {
      @include dataFieldWidth(80);
    }
  }

  .dataDimensions {
    @media (max-width: 1023px) {
      &:before {
        content: "Mitat";
        @include dataFieldBefore();
      }
    }
    @media (min-width: 1024px) {
      @include dataFieldWidth(110);
    }
  }

  .dataTable .dataProductName {
    background: url(../../assets/cargo--sm.svg) 0 50%/18px 18px no-repeat;
    padding-left: 35px;
  }

.root {
  padding: 20px;
  @media (min-width: 768px) {
    padding: 20px 50px;
  }
}
.completedHeader {
  display: none;
  margin-left: 99px;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    display: block;
  }

  .time,
  .type,
  .destination {
    color: #7a868d;
    display: inline-block;
    line-height: 28px;
    font-size: 14px;
    vertical-align: middle;
  }

  .time {
    width: 65px;
  }
  .type {
    width: 110px;
  }
}

.upcoming {
  + .upcoming {
    margin-top: 15px;

    @media (min-width: 768px) {
      margin-top: 55px;
    }
  }
  @media (min-width: 768px) {
    .destinations {
      margin-left: 60px;
    }
  }
}

.completed {
  + .completed {
    margin-top: 20px;
  }

  .destinations {
    border-radius: 3px;
    box-shadow: 0 2px 4px 0 rgba(#c2c6ca, 0.5), 0 1px 0 0 rgba(#c2c6ca, 0.5);
    margin: 0;
    overflow: hidden;

    @media (min-width: 768px) {
      margin: 0 0 0 70px;
    }
  }
}

.noMore {
  color: #7a868d;
  font-size: 22px;
  line-height: 40px;
  margin-top: 55px;
  opacity: 0.5;
  padding-left: 60px;
  position: relative;

  @media (max-width: 767px) {
    margin-left: 20px;
  }

  &:before {
    border: 2px solid #7a868d;
    border-radius: 100%;
    box-sizing: border-box;
    content: "";
    display: inline-block;
    position: absolute;
    left: 0;
    width: 40px;
    height: 40px;
  }

  &:after {
    border-left: 2px solid #7a868d;
    border-bottom: 2px solid #7a868d;
    content: "";
    display: inline-block;
    position: absolute;
    top: 13px;
    left: 11px;
    height: 7px;
    width: 17px;
    transform: rotate(-45deg);
  }
}

.buttonWrapper {
  display: flex;
  justify-content: center;
  transform: scale(0.6);
  margin-bottom:15px;
  transform-origin: right;

  @media (min-width: 768px) {
    justify-content: flex-end;
    transform: scale(0.7);
    margin-bottom:25px;
  }
}

.buttonContainer {
  margin: 0 0 0 25px;
}

.updateDestinations {

  > button {
    margin: 0 auto;
  }
}

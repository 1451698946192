.mapWrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  bottom: 80px;
  @media (min-width: 768px) {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  > div {
    margin: 0;
  }
}

.mapWrapperOnlyTransactions {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  bottom: 0px;
  @media (min-width: 768px) {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  > div {
    margin: 0;
  }
}

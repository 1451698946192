.root {
  position: relative;

  .details {
    border-left-style: solid;
  }

  &.load {
    .details {
      border-left-color: #2479b3;
    }
    .type {
      color: #2479b3;
    }
    .arrowCircle {
      background: #2479b3;

      &:hover {
        background-color: lighten(#2479b3, 3%);
      }

      &:active {
        background-color: darken(#2479b3, 3%);
      }
    }
  }

  &.unload {
    .details {
      border-left-color: #cc5198;
    }
    .type {
      color: #cc5198;
    }
    .arrowCircle {
      background: #cc5198;

      &:hover {
        background-color: lighten(#cc5198, 3%);
      }

      &:active {
        background-color: darken(#cc5198, 3%);
      }
    }
  }

  &.cancelled {
    .details {
      opacity: 0.75;
      border-left-color: #7f7f7f;
    }
    .type {
      color: #7f7f7f;
    }
    color: #7f7f7f;
  }

  &.visited {
    .details {
      border-left-color: #57a50b;
    }
    .type,
    .destination {
      color: #57a50b;
    }
  }
}

.note {
  font-family: Montserrat;
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
  position: absolute;
  top: 12px;
  right: 10px;
  z-index: 1;

  @media (min-width: 768px) {
    top: 17px;
    right: 30px;
  }

  .added,
  .updated {
    color: #57a50b;
  }
  .cancelled {
    color: #f26161;
  }
  .delete {
    width: 40px;
    height: 40px;
    background: #F9F9F9 url(../assets/trashcan.svg) 50% 50%/auto 20px no-repeat;
    border-radius: 100%;
    position: absolute;
    top: 30px;
    right: 0;
    cursor: pointer;

    @media (min-width: 768px) {
      background-size: 50% 23px;
      width: 50px;
      height: 50px;
      top: 40px;
      right: auto;
    }
  }
}

.upcoming {
  .details {
    border-left-width: 7px;
    padding: 10px 20px 25px 50px;

    @media (min-width: 768px) {
      border-left-width: 12px;
      padding: 15px 40px 20px;
    }
  }

  &.root {
    margin-top: 20px;
  }

  .time {
    color: #7a868d;
    font-size: 12px;
    width: 43px;
    text-align: right;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;

    @media (min-width: 768px) {
      font-size: 16px;
      left: -72px;
      width: 45px;
    }

    span.start,
    span.end {
      display: inline-block;
      line-height: 32px;
      position: absolute;
      left: 0;
      right: 0;
    }

    .start {
      top: 5px;

      @media (min-width: 768px) {
        top: 10px;
      }
    }

    .end {
      bottom: 5px;

      @media (min-width: 768px) {
        bottom: 10px;
      }
    }

    .arrow {
      position: absolute;
      top: 35px;
      right: 13px;
      bottom: 45px;

      @media (min-width: 768px) {
        top: 50px;
        right: 15px;
        bottom: 60px;
      }

      &:before {
        content: "";
        display: inline-block;
        width: 1px;
        border-right: 1px dashed #7a868d;
        height: 100%;

        @media (min-width: 768px) {
          border-right-width: 2px;
          width: 2px;
        }
      }

      &:after {
        content: "";
        display: inline-block;
        width: 4px;
        height: 4px;
        border-right: 1px solid #7a868d;
        border-bottom: 1px solid #7a868d;
        transform: rotate(45deg);
        position: absolute;
        right: -2px;
        bottom: -6px;

        @media (min-width: 768px) {
          border-right-width: 2px;
          border-bottom-width: 2px;
          bottom: -8px;
        }
      }
    }
  }

  .destination {
    font-size: 20px;
    font-weight: 700;
    margin: 8px 45px 12px 0;

    @media (min-width: 768px) {
      font-size: 24px;
      margin-right: 0;
    }
  }

  .city {
    color: #6f7b82;

    @media (min-width: 768px) {
      margin-left: 5px;
    }
  }
}

.completed {
  .details {
    background: #fff url(../../../assets/chevron-right-grey.svg)
      calc(100% - 15px) 50%/9px 16px no-repeat;
    border-radius: 0;
    border-left-width: 4px;
    padding: 13px 15px;

    @media (min-width: 768px) {
      background-position: calc(100% - 30px) 50% !important;
      padding: 13px 25px;
    }
  }

  + .completed {
    border-top: 2px solid #e5e9ed;
  }

  .time,
  .destination {
    display: inline-block;
    vertical-align: middle;
  }

  .time {
    font-size: 14px;
    width: 65px;
    text-align: right;
    padding-right: 30px;
  }

  .destination {
    white-space: nowrap;
    text-align: left;
  }
}

.arrowCircle {
  @media (min-width: 768px) {
    border-radius: 100%;
    display: inline-block;
    width: 50px;
    height: 50px;
    position: absolute;
    top: calc(50% - 25px);
    right: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:after {
      background: url(../../../assets/chevron-right-white.svg) 50%/100% auto
        no-repeat;
      content: "";
      display: inline-block;
      width: 11px;
      height: 18px;
    }
  }
}

.root {
  button {
    margin-top: 20px;
  }
}

.label {
  color: #45555F;
  display: block;
  font-size: 16px;
  line-height: 1.5;
  text-align: left;
}

.field {
  > div {
    background: #DBF3DF;
    border: 1px solid rgba(#cbcbcb, 0.4);
    border-radius: 3px;
    box-shadow: inset 0 1px 3px 0 rgba(#45555F, .5);
    display: block;
    font-size: 16px;
    width: 300px;
    height: 50px;
    line-height: 50px;
    padding: 0 15px;
    
    @media (min-width: 768px) {
      width: 360px;
    }

    input {
      height: 100%;
      padding: 0;
    }
  }

  > div:before,
  > div:after {
    display: none;
  }

  + .field {
    margin-top: 20px;
  }
}
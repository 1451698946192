/*@mixin stateBar($width) {
    background: #2479b3;
    border-radius: 0 100px 100px 0;
    content: "";
    display: inline-block;
    height: 4px;
    width: $width;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;
  } */

  .stepIconActive {
    color: #f26161 !important; /*This needs correctly implemeneted without important*/
  }
  .stepIconCompleted {
    color: #f26161 !important; /*This needs correctly implemeneted without important*/
  }
  
  .label {
    color: #45555f;
    display: block;
    font-size: 13px;
    line-height: 1.5;
    text-align: left;

    @media (min-width: 768px) {
      font-size: 16px;
    }
    
  }

  .inputGroup {
    border: none;
    margin-top: 25px;

    legend {
      color: #45555f;
      font-family: Montserrat;
      letter-spacing: 0.8px;
      text-align: center;
      text-transform: uppercase;
      line-height: 25px;
      width: 80%;
      margin: auto;
    }

    .inputGroupInfo {
      color: #45555f;
      font-family: Montserrat;
      letter-spacing: 0.8px;
      font-size: 12px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;
    }

    .inputs {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    .fieldlabel {
      color: #45555f;
      font-size: 11px;
      line-height: 1.5;
      text-align: left;
      width: 100%;

      // Hack for really small screens
      @media (min-width: 380px) {
        font-size: 13px;
      }

      @media (min-width: 768px) {
        font-size: 16px;
      }

      input {
        text-align: center;
        padding: 0 0 0 0;
      }

    }

    .fieldmultiline {
      color: #45555f;
      font-size: 13px;
      line-height: 1.5;
      text-align: left;
      width: 100%;

      @media (min-width: 768px) {
        font-size: 16px;
      }

      background: #fff;
      border: 1px solid rgba(#45555f, 0.4);
      border-radius: 3px;
      box-shadow: inset 0 1px 3px 0 rgba(#45555f, 0.5);

      & > div > div {
        border: none;
        padding-left: 10px;
        padding-right: 10px;

        fieldset {
          top: -10px;
          bottom: 5px;
          left: 5px;
          right: 5px;
        }
      }

    }

    .dateTimeFieldContainer,
    .timeFieldWrapper,
    .dateFieldWrapper {
      width: 49%;
      text-align: left;
    }
    .timeFieldWrapper {
      width: 39%;
    }
    .dateFieldWrapper {
      width: 59%;
    }

    .dateTimeFieldSpacer {
      width: 2%;
    }

    .dateFieldContainer,
    .timeFieldContainer {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      background: #fff;
      border: 1px solid rgba(#45555f, 0.4);
      border-radius: 3px;
      box-shadow: inset 0 1px 3px 0 rgba(#45555f, 0.5); 
      width: 100%;

      label {
        text-align: left;
      }
    }

    .dateTimeFieldShort {    
      width: 25px;

      // Hack for really small screens
      @media (min-width: 380px) {
        width: 35px;
      }

      input {
        text-align: center;
        padding: 6px 0 7px;
      }

      fieldset {
        padding: 0 0 0 0;
        top: -10px;
        bottom: 5px;
      }
    }
    .dateTimeFieldLong {    
      width: 45px;

      // Hack for really small screens
      @media (min-width: 380px) {
        width: 55px;
      }

      input {
        text-align: center;
        padding: 6px 0 7px;
      }

      fieldset {
        padding: 0 0 0 0;
        top: -10px;
        bottom: 5px;
      }
    }

    span {
      line-height: 32px;
    }

    .invalid {
      background: lighten(#f26161,20%);
    }

    .formFieldError {
      color: darken(#f26161,10%);
      font-size: 0.7em;
      margin: 5px auto 10px 0;
    }

    .formFieldStatus {
      color: darken(#45555f,10%);
      font-size: 0.7em;
      margin: 5px 0 10px auto;
    }


 }
  
  .confirmation {
    @media (min-width: 768px) {
      width: 360px;
    }
  
    .userInfo {
      line-height: 1.5;
      margin: 0 0 20px;
  
      @media (min-width: 768px) {
        margin: 0 30px 30px;
      }
  
      span {
        display: inline-block;
        text-align: left;
        width: 50%;
  
        &.infolabel {
          font-weight: 700;
        }
      }
    }
  }
  
  .submit {
    margin-top: 30px;
  }
  
.root {
  position: absolute;
  z-index: 10;
  padding: 30px 20px;
  width: 100%;
  height: 100%;
  left: 0;

  @media (min-width: 768px) {
    padding: 70px 60px;
  }

  :before {
    background: transparentize(#45555f, 0.5);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
  }
}

.content {
  padding: 35px;
  background: #f5f6f7;
}

.heading {
  color: #f26161;
  font-size: 28px;
  font-family: Montserrat;
  font-weight: 300;
  text-align: center;
  letter-spacing: -0.03px;
  line-height: 32px;
  margin: 0 0 30px;
}

.subheading {
  width: 100%;
  height: 24px;
  color: #45555f;
  font-size: 16px;
  font-family: Roboto;
  font-weight: normal;
  text-align: center;
  letter-spacing: -0.01px;
  line-height: 24px;
}

.orders {
  margin-top: 30px;
}

.controls {
  text-align: center;
  margin-top: 30px;
}

html, body {
  margin: 0;
  padding: 0;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  min-height: 100%;
  height: 100%;
  width: 100%;
  background: #FAFBFA;
  color: #34404A;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
}

body * {
  box-sizing: border-box;
}

#root, .awsappsync {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.awsappsync,
.awsappsync > div {
  flex: 1;
}

.fullsize {
  width: 100%;
  min-height: 100%;
}

.flexcolumn {
  display: flex;
  flex-direction: column;
}

.flexrow {
  display: flex;
  flex-direction: row;
}

@media (min-width: 768px) {
  .flexcolumn, .flexrow {
    position: relative;
  }
}
.tableRow {

  background: #fff !important;
  box-shadow: 0 2px 4px 0 rgba(69, 85, 95, 0.25),
    0 1px 0 0 rgba(69, 85, 95, 0.31);
  cursor: pointer;
  height: 60px !important;
  position: relative;

  td {
    background: inherit;
    &:last-child {
      padding-right: 74px !important;
    }
  }

  &:not(.invalid):not(.loading):not(.deleting){
    td {
      &:last-child {
        background: url(../../assets/chevron-right-grey.svg)
          calc(100% - 30px) 50%/9px 16px no-repeat
      }
    }
  }

  &:last-child {
    box-shadow: 0 2px 4px 0 rgba(69, 85, 95, 0.25);
  }

  &.invalid {
    cursor: default;
  }

  .iconButton {
    padding: 8px;
  }

  .iconButtonInactive {
    opacity: 0.5;
  }
}

.loading {
  opacity: 0.5;
}

.invalid {
  background: darken(#fff, 15%) !important;
}

.action {
  width: 45px;
}

.spinner {
  transform: scale(0.3);
  display: inline-block;
  margin: 0;
  width: 16px;
  height: 16px;
}
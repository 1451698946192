.root {
  flex: 1;
  width: auto;
  height: auto;
  margin: 0;
  padding: 10px;
  min-width: 0;
  min-height: 0;
  line-height: 1;
  font-size: 14px;
  & + .root {
    margin-top: 0;
    margin-left: 5px;
  }
  .title {
    display: block;
    margin-top: 10px;
  }
  .icon {
    width: 70px;
    height: 70px;
  }
}


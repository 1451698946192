.fieldWrapper {
  display: inline-block;
  position: relative;
  width: 100%;

  &.valid {
    .field {
      background: #dbf3df;
      
    }
    &:after {
      background: url(../../assets/complete.svg) 0 0/30px 30px no-repeat;
      content: "";
      display: inline-block;
      width: 30px;
      height: 30px;
      position: absolute;
      top: -15px;
      right: -15px;
      z-index: 2;
    }
  }
}

.field {
  background: #f9f9f9;
  border: 1px solid rgba(#cbcbcb, 0.4);
  border-radius: 3px;
  box-shadow: inset 0 1px 3px 0 rgba(#45555f, 0.5);
  display: block;
  font-size: 16px;
  width: 300px;
  height: 50px;
  line-height: 50px;
  padding: 0 15px;

  @media (min-width: 768px) {
    width: 360px;
  }

  &.unit {
    padding-right: 50px;
  }
}

.root {
  text-transform: capitalize;

  span {
    &:first-child {
      display: none;

      @media (min-width: 768px) {
        display: inline;
      }
    }
    @media (min-width: 768px) {
      &:last-child {
        display: none;
      }
    }
  }
}

.upcoming {
  color: #7A868D;
  font-size: 18px;
  line-height: 32px;
  margin: 0 0 15px;
  text-align: center;
  text-transform: capitalize;

  @media (min-width: 768px) {
    padding-left: 110px;
    text-align: left;
  }
}
.completed {
  font-size: 18px;
  margin: 0 auto 20px;
  text-align: center;

  @media (min-width: 768px) {
    position: absolute;
    width: 30px;
  }

  span {
    font-size: 14px;
  }
}
.root {
  background: url(./assets/notice.svg) 0 0 no-repeat;
  color: #45555f;
  font-size: 14px;
  line-height: 1.5;
  margin-top: 15px;
  padding-left: 30px;

  @media (min-width: 767px) {
    max-width: 750px;
  }
}
.collapsed {
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
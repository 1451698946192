.root {
  display: flex;
  flex-direction: column;
  // min-height: 100%;
  max-width: 100%;
}

.main {
  > div {
    max-width: 100%;
  }
  @media (max-width: 767px) {
    > div {
      padding-bottom: 100px;
    }
  }
  @media (min-width: 768px) {
    display: flex;
    flex: 1;
    // min-height: 100%;

    > div {
      flex: 1;
      /*padding: 20px 50px;*/
    }
  }
}

.root {
  margin: 20px 0;
  
  @media (min-width: 768px) {
    max-width: 600px;
    margin: 30px auto;
    //transform: translateX(-40px);
  }

  header {
    background: url(../components/user/assets/account-lg.svg) 50% 0 no-repeat;
    margin: 0 0 45px;
    padding: 100px 20px 0;
    text-align: center;

    @media (min-width: 768px) {
      padding: 100px 0 0;
    }

    h2 {
      color: #45555F;
      font-family: Montserrat;
      font-size: 28px;
      font-weight: 300;
      margin: 0 0 15px;

      @media (min-width: 768px) {
        font-size: 36px;
        letter-spacing: .4px;
      }
    }
    .company {
      color: #7A868D;
      display: block;
      margin: 0 0 50px;
    }

    :global .logout {
      background: url(../components/user/assets/logout.svg) 5px 50% no-repeat;
      padding-left: 45px;
    }
  }
}

.fieldset {
  .legend {
    color: #7A868D;
    display: inline-block;
    font-family: Montserrat;
    font-weight: 300;
    line-height: 32px;
    margin: 0 0 5px;

    @media (max-width: 767px) {
      margin-left: 25px;
    }
  }
  .fields {
    .field {
      display: flex;
      justify-content: space-between;
      min-height: 60px;
      padding: 18px 25px;
      line-height: 24px;

      .fieldContent {
        span {
          display: block;

          + span {
            margin-top: 5px;
          }

          &.heading {
            font-size: 18px;
          }

          &.meta {
            color: #7A868D;
          }
        }
      }

      span, a {
        &.disabled {
          color: #7A868D;
        }

        &.link {
          color: #2479B3;
          cursor: pointer;
          text-decoration: none;
        }
      }
    }
  }

  + .fieldset {
    margin-top: 40px;
  }
}
.summary {
  background: #fff;
  border-radius: 6px 6px 0 0;
  box-shadow: 0 2px 4px 0 rgba(#45555f, 0.25);
  padding: 20px 0 0;

  @media (min-width: 768px) {
    padding: 35px 55px 45px 65px;
  }

  h2 {
    font-size: 32px;
    margin: 0 15px 10px;
  }
}

.additionalInfo {
  margin: 20px;
  padding-bottom: 15px;
}

.showOnMapButton {
  background-image: url(../assets/main-menu--map--inactive.svg);
  background-color: transparent;
  background-position: 50% 8px;
  background-size: auto;
  background-repeat: no-repeat;
  color: blue;
  display: inline-block;
  hyphens: manual;
  font-size: 15px;
  padding: 38px 10px 0;
  position: relative;
  text-decoration: underline;
  white-space: normal;
}

.type {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.8px;
  margin: 0 15px 15px;
  text-transform: uppercase;

  &.load {
    color: #2479b3;
  }
  &.unload {
    color: #cc5198;
  }
}

.contactDetails {
  @media (min-width: 768px) {
    display: flex;
  }
}

.location,
.contact {
  // overflow: hidden;
  // white-space: nowrap;
  // text-overflow: ellipsis;
  border-bottom: 1px solid #dfe3e5;
  color: #45555f;
  display: flex;
  align-items: center;
  min-height: 50px;
  // line-height: 50px;
  padding: 15px 20px 13px 50px;
  width: 100%;

  @media (min-width: 768px) {
    font-size: 18px;
    flex: 1;
  }
}

.location {
  background: url(../assets/location.svg) 22px 50% no-repeat;
}

.contact {
  background: url(../assets/user--sm.svg) 20px 50% no-repeat;
}

.notices {
  background: url(../assets/notice.svg) 19px 0 no-repeat;
  color: #45555f;
  font-size: 14px;
  line-height: 1.5;
  margin-top: 15px;
  padding-left: 50px;
  margin-right: 240px;
}

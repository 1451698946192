.root {
  counter-reset: destination;
  padding: 35px 20px 0;
  margin: 0 0 20px;

  @media (min-width: 768px) {
    padding: 35px 60px 0;
  }
  
  h2 {
    font-size: 22px;
    font-weight: 400;
    margin: 0 0 10px;
  }
}

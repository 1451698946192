.root {
  background: url(./assets/add-new.svg) calc(100% - 60px) 50% no-repeat;
  border: 2px dashed #84b2d1;
  border-radius: 3px;
  color: #2479B3;
  cursor: pointer;
  display: block;
  height: 60px;
  line-height: 55px;
  margin: 20px 0;
  padding: 0 60px;
  font-size: 18px;
  font-weight: 700;
  text-decoration: none;

  @media (max-width: 767px) {
    background-position: calc(100% - 20px) 50%;
    margin: 20px;
    padding: 0 20px;
  }
}
@mixin stateBar($width) {
  background: #2479b3;
  border-radius: 0 100px 100px 0;
  content: "";
  display: inline-block;
  height: 4px;
  width: $width;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 1;
}

.state {
  margin: 0 -20px 20px;
  position: relative;

  @media (min-width: 768px) {
    margin: 0 -50px 20px;
  }

  &:after {
    background: #e7e7e7;
    content: "";
    display: inline-block;
    height: 4px;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
  }

  span {
    display: inline-block;
    font-family: Montserrat;
    letter-spacing: 0.8px;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    height: 54px;
    line-height: 54px;
    width: 50%;
  }
}

.label {
  display: block;
  font-size: 16px;
  line-height: 1.5;
  text-align: left;
}

.confirmation {
  @media (min-width: 768px) {
    width: 360px;
  }

  .userInfo {
    line-height: 1.5;
    margin: 0 0 20px;

    @media (min-width: 768px) {
      margin: 0 30px 30px;
    }

    span {
      display: inline-block;
      text-align: left;
      width: 50%;

      &.infolabel {
        font-weight: 700;
      }
    }
  }
}

.phonePrefix {
  width: 75px !important;
  display: inline-block !important;
  margin-right: 5px;
}

.phoneBody {
  width: 220px !important;
  display: inline-block !important;

  @media (min-width: 768px) {
    width: 280px !important;
  }
}

.submit {
  margin-top: 30px;
}

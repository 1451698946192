.root {
  padding: 20px 0px;

  @media (min-width: 768px) {
    padding-right: 50px;
    padding-left: 50px;
  }

  header {
    color: #45555f;
    font: 300 28px/0.86 Montserrat;
    margin: 0 0 40px;
  }

  > div {
    width: 100%;
  }

  .buttonMargin {
    margin-top: 40px;
  }
}
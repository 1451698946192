.loginHeader {
  background: url(./assets/vrt-logo.svg) 50% 0/282px 18px no-repeat;
  font: 700 24px/1.14 Montserrat;
  letter-spacing: -.5px;
  margin: 40px auto 20px auto;
  padding-top: 45px;
  text-align: center;
  width: 282px;

  @media (min-width: 768px) {
    background-size: auto;
    width: auto;
    min-width: 430px;
    font-size: 28px;
    margin: 80px 0 40px;
  }
}

// extend loginHeader class in all aspects exept for background image
.simpleHeader {
  @extend .loginHeader;
  background: none;
}

.simpleHeader:before {
  content: "Kuljettava Oy\A";
  white-space: pre-wrap;
}

.error {
  border: 1px solid #F26161;
  color: #F26161;
  margin: 20px;
  padding: 10px;
}

.root {
  margin: 0 auto 40px auto;
  padding: 0 25px;

  @media (min-width: 768px) {
    background: #FFF;
    border-radius: 3px;
    box-shadow: 0 25px 35px -10px rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.06);
    margin-bottom: 60px;
    padding: 40px 50px 50px;
    width: 430px;
  }

  > div {
    width: 100%;
  }

  .field {
    > div {
      background-position: 25px 50%;
      background-repeat: no-repeat;
      font-size: 18px;
      height: 60px;
      margin-bottom: 25px;
      padding-left: 65px;
      padding-right: 25px;

      @media (min-width: 768px) {
        font-size: 20px;
      }

      + p {
        position: absolute;
        bottom: 0;
      }
    }

    &.username > div {
      background-image: url(./assets/login--username.svg);
    }

    &.password > div {
      background-image: url(./assets/login--password.svg);
    }
  }
  
  button {
    height: 60px;
    margin-top: 20px;

    @media (min-width: 768px) {
      margin-top: 40px;
    }
  }

  &.login {
    button {
      background: #2479B3 url(./assets/login--submit.svg) 20px 50% no-repeat;

      &:hover {
        background-color: lighten(#2479b3, 3%);
      }

      &:active {
        background-color: darken(#2479b3, 3%);
      }
    }
  }

  &.signup {
    @media (min-width: 768px) {
      width: 500px;
    }

    .label {
      color: #45555F;
      display: block;
      font-size: 16px;
      line-height: 1.5;
      margin: 20px 0 10px;
    }
  }
}

.extraLink {
  color: #7F7F7F;
  display: block;
  font-size: 13px;
  padding: 10px 0;
  text-align: center;

  @media (min-width: 768px) {
    font-size: 15px;
    padding: 15px 0;
  }
}
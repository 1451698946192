.root {
  position: relative;
  background: #2479b3;
  border: 0;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  font-family: Montserrat;
  font-weight: 700;
  font-size: 18px;
  margin: 5px 5px 5px 0;
  min-width: 220px;
  height: 50px;
  line-height: 50px;
  padding: 0 20px;
  text-transform: uppercase;

  &:hover {
    background-color: lighten(#2479b3, 3%);
  }

  &:active {
    background-color: darken(#2479b3, 3%);
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &.exception {
    background: transparent;
    border: 2px solid #f26161;
    color: #f26161;
    font-family: Roboto;
    font-weight: 700;
    height: 50px;
    line-height: 50px;
    text-transform: uppercase;
  }

  &.cancel {
    color: #7a868d;
    background: #fff;

    &:hover {
      background-color: darken(#fff, 3%);
    }

    &:active {
      background-color: darken(#fff, 6%);
    }
  }

  &.block {
    display: block;
    width: 100%;
  }

  &.loading {
    &:disabled {
      > .content {
        visibility: hidden;
      }
      > .loadingSpinner {
        display: block;
      }
    }
  }

  > .loadingSpinner {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    transform: scale(0.5);
  }

  &.green {
    background: #57a50b;

    &:hover {
      background-color: lighten(#57a50b, 3%);
    }

    &:active {
      background-color: darken(#57a50b, 3%);
    }
  }

  &.ghost {
    background: transparent;
    border-style: solid;
    border-width: 1px;
    border-color: inherit;
    color: inherit;

    &:hover {
      background-color: #fff;
    }

    &:active {
      background-color: darken(#fff, 8%);
    }
  }

  + .root {
    margin-top: 10px;
  }
}

.root {
  padding: 4px;
  padding-right: 10px;
  width: 65px;
  height: 55px;
  min-width: 0!important;
  height: auto!important;
  position: relative;
  .icon {
    width: 100%;
    height: auto;
    display: block;
  }
  .plus {
    position: absolute;
    width: 22px;
    height: 22px;
    top: 18px;
    right: 4px;
    border-radius: 999px;
    background-color: #2479b3;
    padding: 1px;
  }
}
.root {
  counter-reset: destination;
  padding: 25px 0 180px;

  @media (min-width: 768px) {
    padding: 45px 60px;
  }

  header {
    margin: 0 20px 30px;

    > div {
      display: inline-block;
      vertical-align: top;
      // width: 25%;

      &:first-child {
        // width: 50%;
      }

      &.finished {
        color: #57a50b;
      }
    }

    h2 {
      font-size: 24px;
      line-height: 1;
      margin: 0 0 10px;
    }

    .commodity {
      background: url(../assets/cargo--sm.svg) 0 2px no-repeat;
      display: block;
      font-size: 18px;
      padding-top: 2px;
      padding-left: 30px;
    }

    .title {
      display: inline-block;
      font-family: Montserrat;
      font-size: 14px;
      font-weight: 300;
      line-height: 24px;
      letter-spacing: 0.54px;
      margin: 0 0 10px;
      text-transform: uppercase;
    }

    .commodityAmount {
      display: block;
      font-size: 22px;
      font-weight: 700;
    }
  }
}

.root {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
}

.modal {
  background-color: #fff;
  text-align: center;
  margin: 0 20px;
  padding: 20px;
  box-shadow: 0 1px 10px 0 #7A868D;
  position: relative;

  max-height: 100%;
  overflow-y: auto;

  @media (min-width: 768px) {
    margin: 0;
    max-width: 460px;
    padding: 40px 50px 50px;
  }

  h3 {
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 300;
    letter-spacing: -0.5px;
    margin: 0 0 20px;

    @media (min-width: 768px) {
      font-size: 28px;
    }

  }

}

.veil {
  background-color: rgba(#45555f, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  &Contained {
    @extend .veil;
    position: absolute;
  }
}
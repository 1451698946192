.fields {
  @media (min-width: 768px) {
    display: flex;
    margin: 20px -10px;
  }

  margin-top: 20px;

  /* Hiding the spinner */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0;
  }
}

.gridColumn {
  @media (min-width: 768px) {
    flex: 1;
    margin: 0 8px;
  }
  position: relative;

  input {
    width: 100%;
  }
}

.fieldWrapper {
  position: relative;
}

.containersTitle {
  color: rgba(#000000, 0.7);
  display: block;
  margin-bottom: -14px;
  font-size: 18px;
  font-weight: 700;
  white-space: nowrap;
}

.gridTitle {
  color: rgba(#45555f, 0.7);
  display: block;
  font: 300 13px/24px "Montserrat";
  text-transform: uppercase;
  white-space: nowrap;
}

.unitLabel {
  color: #93ad93;
  position: absolute;
  right: 15px;
  top: 50%;
}

.errorLabel {
  color: #f26161;
  font-size: 0.8rem;
}

.waybillFieldContainer {
  white-space: nowrap;
  width: 100%;
  min-width: 14em;
}

.waybillFieldInput {
  width: calc(100% - 7em);
  input {
    width: 100%;
  }
}

.informationText {
  display: block;
  color: #C21F39;
  font: 200 10px/20px "Montserrat";
  margin-top: 1px;
}

.getButton {
  display: inline-block;
  font-size: 10px;
  width: 5em;
  min-width: 5em;
  max-width: 5em;
  padding: 0px;
  margin: 0 5px;
  vertical-align: top;
  text-align: center;
}

.copyButton {
  display: inline-block;
  font-size: 10px;
  width: 5em;
  min-width: 5em;
  max-width: 5em;
  padding: 0px;
  margin: 0;
  margin-top: 0px !important;
  vertical-align: top;
  text-align: center;
}

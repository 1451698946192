.table {
  @media (max-width: 767px) {
    max-width: 100vh;
    overflow-x: scroll;
  }
}
thead {
  th {
    border: 0;
    font-size: 14px !important;
    padding: 10px !important;
  }
}

tbody {
  th {
    font-size: 14px !important;
    font-weight: 700 !important;
    padding-right: 10px !important;

    @media (max-width: 767px) {
      padding: 10px !important;
      min-width: 170px;
    }
  }
  td {
    font-size: 14px;
    padding: 10px !important;

    @media (max-width: 767px) {
      word-break: break-word;
      min-width: 170px;
    }
  }
}

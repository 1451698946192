.state {
  margin: 0 -20px 20px;
  position: relative;

  @media (min-width: 768px) {
    margin: 0 -50px 20px;
  }

  &:after {
    background: #e7e7e7;
    content: "";
    display: inline-block;
    height: 4px;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
  }

  span {
    color: #45555f;
    display: inline-block;
    font-family: Montserrat;
    letter-spacing: 0.8px;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    height: 54px;
    line-height: 54px;
    width: 50%;
  }
}

.confirmation {
  @media (min-width: 768px) {
    width: 360px;
  }

  .userInfo {
    line-height: 1.5;
    margin: 0 0 20px;

    @media (min-width: 768px) {
      margin: 0 30px 30px;
    }

    span {
      display: inline-block;
      text-align: left;
      width: 50%;

      &.infolabel {
        font-weight: 700;
      }
    }
  }
}

.root {
  border-left-width: 12px;
  border-left-style: solid;
  &.load {
    border-left-color: #2479b3;
    .type {
      color: #2479b3;
    }
  }

  &.unload {
    border-left-color: #cc5198;
    .type {
      color: #cc5198;
    }
  }

  &.cancelled {
    opacity: 0.75;
    border-left-color: #7f7f7f;
    color: #7f7f7f;
    .type {
      color: #7f7f7f;
    }
  }

  h2 {
    font-size: 1em;
    margin: 5px 0;
    @media (min-width: 768px) {
      font-size: 1.4em;
      margin: 10px 0;
    }
  }
}

.column {
  flex-direction: column;
}

.row {
  flex-direction: row;
}

.flex {
  display: flex;
  flex: 1;
}

.titleRow {
  display: flex;
  flex-direction: column;
  align-items: baseline;
}

.additionalInfo {
  margin: 20px;
  padding-bottom: 15px;
}

.type {
  font-family: Montserrat;
  font-size: 9px;
  font-weight: 700;
  letter-spacing: 0.8px;
  margin: 0 15px 0 0;
  text-transform: uppercase;
  flex: 0;
  @media (min-width: 768px) {
    font-size: 14px;
  }

  &.load {
    color: #2479b3;
  }
  &.unload {
    color: #cc5198;
  }
}

.details {
  margin-top: -20px;
  font-size: 14px;
  @media (min-width: 768px) {
    font-size: 18px;
  }
}

.contactDetails {
  @media (min-width: 768px) {
    display: flex;
  }
}

.location,
.contact {
  // overflow: hidden;
  // white-space: nowrap;
  // text-overflow: ellipsis;
  color: #45555f;
  display: flex;
  align-items: center;
  line-height: 30px;
  // line-height: 50px;
  padding: 0 20px 0 25px;
  width: 100%;

  @media (min-width: 768px) {
    flex: 1;
  }
}

.location {
  background: url(../assets/location.svg) 2px 50% no-repeat;
}

.contact {
  background: url(../assets/user--sm.svg) 0px 50% no-repeat;
}

.notices {
  background: url(../assets/notice.svg) 0px 0 no-repeat;
  color: #45555f;
  font-size: 14px;
  line-height: 1.5;
  margin-top: 15px;
  padding-left: 50px;
  margin-right: 240px;
}

.accordionsummary {
  background-color: transparent !important;
}

.root {
  background: #fff;
  box-shadow: 0 1px 10px 0 #7A868D;
  line-height: 1.5;

  @media (min-width: 768px) {
    border-radius: 3px;
    box-shadow: 0 2px 10px 0 rgba(#C2C6CA, .5),
    0 2px 0 0 rgba(#C2C6CA, .5);
  }

  &.multiple {
    border-radius: 0;

    + .multiple {
      margin-top: 1px;
    }
    &:first-of-type {
      border-radius: 3px 3px 0 0;
    }

    &:last-of-type {
      border-radius: 0 0 3px 3px;
    }
  }
}
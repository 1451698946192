.root {
  border-radius: 100%;
  display: inline-block;
  width: 26px;
  height: 26px;
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform-origin: 50%;

  @media (min-width: 768px) {
    width: 50px;
    height: 50px;
    top: calc(50% - 25px);
    right: 40px;
  }

  &:after {
    background: url(../../assets/complete.svg) 50%/100% auto no-repeat;
    content: "";
    display: inline-block;
    width: 100%;
    height: 100%;
  }
}

.root {
  padding: 0 0 150px;

  @media (min-width: 768px) {
    padding: 45px 60px;
  }

  header {
    border-bottom: 4px solid rgba(#45555f, 0.16);
    padding: 0 20px 35px;

    @media (min-width: 768px) {
      margin: 0 -60px;
      padding: 0 60px 35px;
    }
  }
}

.content {
  padding: 20px;
}

.orderSummaryGrid {
  @media (min-width: 768px) {
    display: flex;

    > div {
      flex: 1 1;
      max-width: 25%;

      + div {
        border-left: 1px solid #dfe3e5;
      }
    }
  }
}

.gridElement {
  color: #45555f;
  padding: 5px 0;
  border-bottom: 1px solid #dfe3e5;

  @media (min-width: 768px) {
    padding: 10px 20px;
  }

  span:not(.gridTitle) {
    display: block;
    line-height: 1.77;

    @media (min-width: 768px) {
      font-size: 18px;
    }

    &.number {
      font-family: "Roboto Mono";
      letter-spacing: 1.13px;
      width: 100%;
      word-wrap: break-word;
    }
  }
}

.gridTitle {
  color: rgba(#45555f, 0.7);
  display: block;
  font: 300 13px/24px "Montserrat";
  text-transform: uppercase;
  white-space: nowrap;
}

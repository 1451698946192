.map {
  flex: 1;
  height: 100%;
  position: relative;
}

.bottomLeft,
.topLeft {
  position: absolute;
  top: 0;
  bottom: auto;
  left: 0;
  right: auto;
  display: flex;
  flex-direction: row;
  z-index: 10;
  padding: 15px;
  > header {
    border-radius: 2%;
    > div {
      padding-left: 5px;
      padding-right: 5px;
      > button {
        padding: 8px;
        border-radius: 20%;
        > span {
          > svg {
            margin-right: 0;
            top: 0;
          }
        }
      }
      > div {
        margin-left: 10px;
        border-radius: 5%;
      }
    }
  }
}

.bottomLeft {
  top: auto;
  bottom: 0;
  left: 0;
  right: auto;
}

.updateSpinner {
  transform: scale(0.45);
  margin: -9px auto 0;
}

.toggleButtonContents {
  line-height: 18px;
}

.wrapper {
  background: #34404a;
  display: flex;
  flex-direction: column;
  width: 100%;
  position: absolute;
  z-index: 200;
  // left: 0;
  // top: 0;

  @media (min-width: 768px) {
    padding: 0 10px 10px;
    //top: 25px;
  }

  > header {
    color: #fff;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 300;
    height: 50px;
    padding: 15px 0;
    position: relative;
    text-align: center;

    @media (min-width: 1024px) {
      font-size: 18px;
      font-weight: 700;
      letter-spacing: 0.45px;
      text-transform: uppercase;
    }

    > a {
      background: url(../../assets/arrow-left-white.svg) 10px 50% no-repeat;
      color: #fff;
      display: inline-block;
      font: 400 18px/1.77 Roboto;
      letter-spacing: 0;
      padding-left: 40px;
      position: absolute;
      text-decoration: none;
      text-transform: none;
      left: 0;
      top: 10px;

      @media (min-width: 1024px) {
        text-indent: 0;
      }
    }
    > span {
      display: inline-block;
      max-width: calc(100vw - 50px);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &.loading {
    background: #2479b3;
  }

  &.unloading {
    background: #cc5198;
  }

  &.completed {
    background: #57a50b;

    .time {
      color: #57a50b;
      background: url(../../assets/complete.svg) 0 50% no-repeat;
      background-size: 30px 30px;
    }
  }

  .root {
    background: #fafbfa;
    border-radius: 6px 6px 0 0;
    flex: 1 1;

    @media (max-width: 767px) {
      margin: 0 5px 5px;
    }
  }
}

.root {
  padding: 20px 40px;
  position: relative;

  &:before {
    content: counter(destination);
    counter-increment: destination;
    position: absolute;
    left: 20px;
    top: 20px;

    @media (min-width: 768px) {
      left: 25px;
    }
  }

  + div {
    margin-top: 20px;
  }

  .customerOrderNum,
  .transactionOrderNum,
  .transactionRecipient {
    display: block;

    @media (min-width: 768px) {
      display: inline-block;
    }

    span {
      display: block;
    }

    span.title {
      font-family: Montserrat;
      font-size: 14px;
      font-weight: 300;
      letter-spacing: 0.54px;
      line-height: 24px;
      text-transform: uppercase;

      @media (max-width: 767px) {
        // display: none;
      }

      @media (min-width: 768px) {
        padding-left: 20px;
      }
    }
    span:not(.title) {
      font-size: 18px;
      font-weight: 700;
      line-height: 1.45;
      padding: 5px 15px 10px 25px;
      display: inline-block;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;

      @media (min-width: 768px) {
        font-size: 22px;
        padding-left: 45px;
      }
    }
  }

  .transactionHeader {
    width: 100%;
    line-height: 2;
    @media (min-width: 768px) {
      padding-right: 90px;
    }

    > div {
      div {
        width: 100%;
      }
      @media (min-width: 768px) {
        display: flex;
        width: 100%;
      }
    }
  }

  $customerOrderNumWidth: 220px;
  $transactionOrderNumWidth: 180px;
  $rightMarginWidth: 140px;

  .customerOrderNum,
  .transactionOrderNum {
    border-bottom: 1px solid #dfe3e5;
    width: calc(100% - 25px);
    padding-top: 5px;

    @media (min-width: 768px) {
      border: 0;
      padding: 0;
    }

    span:not(.title) {
      background: url(../assets/order.svg) 0 8px/13px auto no-repeat;
      letter-spacing: 0.88px;

      @media (min-width: 768px) {
        background-position: 20px 10px;
        background-size: auto;
        box-shadow: 0 1px 0 0 #dfe3e5;
        letter-spacing: 1.38px;
      }
    }
  }

  .customerOrderNum {
    padding-top: 0;

    @media (min-width: 768px) {
      width: $customerOrderNumWidth;
      span:not(.title) {
        box-shadow: 0 1px 0 0 #dfe3e5, 1px 0 0 0 #dfe3e5;
      }
    }
  }

  .transactionOrderNum {
    @media (min-width: 768px) {
      width: $transactionOrderNumWidth;
    }
  }

  .transactionRecipient {
    width: calc(100% - 40px);
    padding-top: 5px;

    @media (min-width: 768px) {
      padding: 0;
    }

    @media (min-width: 768px) {
      width: calc(
        100% - #{$customerOrderNumWidth} - #{$transactionOrderNumWidth} - #{$rightMarginWidth}
      );
    }

    span:not(.title) {
      background: url(../../../assets/arrow-right-grey.svg) 0 10px/15px auto
        no-repeat;
      box-shadow: 0 1px 0 0 #dfe3e5;

      @media (min-width: 768px) {
        background-position: 20px 13px;
        background-size: 18px 15px;
      }
    }
  }

  .transaction {
    background: url(../assets/cargo--sm.svg) 0 9px/16px auto no-repeat;
    padding: 5px 25px;

    @media (min-width: 768px) {
      background-position: 18px 16px;
      background-size: auto;
      padding: 15px 48px;
    }

    .transactionCommodityAmount {
      margin-left: 25px;
    }

    .notice {
      background: url(../assets/notice--lightgrey.svg) 0 0 no-repeat;
      color: #7a868d;
      display: block;
      font-size: 14px;
      height: 20px;
      margin-left: -30px;
      margin-top: 10px;
      max-width: 650px;
      padding-left: 30px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.note {
  font-family: Montserrat;
  font-weight: 700;
  font-size: 14px;
  text-transform: uppercase;
  position: absolute;
  top: 21px;
  right: 30px;
  z-index: 1;
  color: #57a50b;
}

.completed {
  background-color: #57a50b;
}

.root {
  margin: 5px;

  input {
    width: 40px;
    height: 40px;
    background: #E3EEF5;
    border: 1px solid rgba(#CBCBCB, .4);
    box-shadow: inset 0 1px 3px 0 rgba(#000, .2);
    border-radius: 3px;
    line-height: 40px;
    font-family: Montserrat;
    font-size: 27px;
    font-weight: 700;
    letter-spacing: -.7px;
    text-align: center;
    text-transform: uppercase;

    @media (min-width: 768px) {
      width: 60px;
      height: 60px;
      line-height: 60px;
    }

    + input {
      margin-left: 5px;
    }
  }

  &.license {
    margin: 0 0 70px;
    position: relative;

    &:before {
      content: "–";
      display: inline-block;
      font: 700 24px/1.33 Montserrat;
      letter-spacing: -.75px;
      position: absolute;
      top: 5px;
      left: calc(50% - 5px);

      @media (min-width: 768px) {
        font-size: 30px;
        top: 10px;
      }
    }

    input {
      background: transparent;

      &:focus {
        background: #CEE4F2;
        outline: none;
      }

      &:nth-child(4) {
        margin-left: 40px;
      }
    }
  }
}
.root {
  position: relative;
  border-left-width: 12px;
  padding: 15px 40px 20px;
  margin: 0;
  max-height: 66px;
  transition: max-height 0.5s, margin 0.5s;
  overflow: hidden;
  &.open, &.open.current {
    max-height: 1000px;
    margin: 5px 0;
    .infoToggle::after {
      transform: rotate(-90deg);
    }
  }
  &.current {
    margin: 0;
    .heading::before {
      background-image: url(../../layout/assets/main-menu--truck--active.svg);
    }
  }

  h3 {
    color: #7A868D;
    display: inline-block;
    font-family: Montserrat;
    font-weight: 300;
    line-height: 32px;
    margin: 40px 0 20px 20px;
  }

  @media (max-width: 767px) {
    padding: 15px 10px 20px;

    h3 {
      margin-left: 0;
    }
  }
}

.headingRight {
  float: right;
}

.infoToggle {
  display: inline-block;
  margin: 0;
  color: #888888;
  font: 300 13px/24px "Montserrat";
  position: relative;
  padding-right: 20px;
  min-width: 125px;
  vertical-align: middle;

  @media (max-width: 767px) {
    min-width: 0;
    width: 11px;
    text-indent: -999px;
    overflow: hidden;
  }

  &::after {
    content: "";
    position: absolute;
    top: 3px;
    right: 0px;
    display: block;
    width: 11px;
    height: 20px;
    background-image: url(../../../assets/chevron-right-grey.svg);
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: 11px 20px;
    transition: transform 0.5s;
    transform: rotate(90deg);
    @media (max-width: 767px) {
      right: 4px;
    } 
  }
}

.selectButton {
  font-size: 13px;
  margin: 0 30px;
  min-width: 100px;
  height: 30px;
  line-height: 30px;
  padding: 0;
  vertical-align: middle;
}

.heading {
  cursor: pointer;
  font-family: "Roboto Mono";
  font-size: 16px;
  letter-spacing: 1px;
  padding: 0;
  text-align: left;
  margin-bottom: 20px;
  line-height: 36px;

  @media (min-width: 426px) {
    font-size: 24px;
  }
  @media (min-width: 768px) {
    &::before {
      content: "";
      display: inline-block;
      width: 25px;
      height: 40px;
      background-image: url(../../layout/assets/main-menu--truck--inactive.svg);
      background-position: 50% 2px;
      background-repeat: no-repeat;
      background-size: 25px;
      vertical-align: middle;
      margin-right: 14px;
      margin-left: -20px;
      @media (max-width: 767px) {
        margin-left: 0;
      }
    }
  }
}

.detailsGridWrapper {
  @media (max-width: 767px) {
    max-width: 100vh;
    overflow-x: auto;
  }
}
.detailsGrid {
  //min-width: 768px;
  > div {
    min-width: 100%;
    width: 100%;
  }

  select {
    width: 100%;
    border: 0;
  }
}

.mobile {
  @media (min-width: 880px) {
    display: none !important;
  }
}
.desktop {
  @media (max-width: 879px) {
    display: none !important;
  }
}

@mixin gridCell($suffix) {
  &.grid75#{$suffix} {
    max-width: 75%;
    flex: 1 1;
  }
  &.grid66#{$suffix} {
    max-width: 66.66%;
    flex: 1 1;
  }
  &.grid50#{$suffix} {
    max-width: 50%;
    flex: 1 1;
  }
  &.grid33#{$suffix} {
    max-width: 33.33%;
    flex: 1 1;
  }
  &.grid25#{$suffix} {
    max-width: 25%;
    flex: 1 1;
  }
}
.grid {
  @media (min-width: 880px) {
    display: flex;
  }
  &.grid_m {
    display: flex;
    > div {
      + div {
        border-left: 1px solid #dfe3e5;
      }
    }
  }

  > div {
    max-width: 100%;
    border-left-color: transparent;
    @include gridCell("m");
    @media (min-width: 880px) {
      @include gridCell("");
      + div {
        border-left: 1px solid #dfe3e5;
      }
    }
    &.gridFixed {
      @include gridCell("");
    }

    
    &.noHorzBorder {
      border-bottom-color: transparent;
    }
    &.noVertBorder {
      border-left-color: transparent;
    }
  }
}

.gridElement {
  color: #45555f;
  padding: 5px;
  border-bottom: 1px solid #dfe3e5;

  @media (min-width: 768px) {
    padding: 10px 20px;
  }

  span:not(.gridTitle) {
    display: block;
    line-height: 1.77;

    @media (min-width: 768px) {
      font-size: 18px;
    }

    &.number {
      font-family: "Roboto Mono";
      letter-spacing: 1.13px;
      width: 100%;
      word-wrap: break-word;
    }
  }
}

.select {
  width: 100%;
  &::before, &::after {
    border: 0 !important;
  }
  @extend .gridTitle;
}

.gridTitle {
  color: rgba(#45555f, 0.7);
  display: block;
  font: 300 13px/24px "Montserrat" !important;
  text-transform: uppercase;
  white-space: nowrap;
}
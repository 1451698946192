.root {
  margin: 0;
  padding: 5px 0 35px;
  // position: sticky;
  // top: 25px;
  z-index: 999;
  text-align: center;

  @media (min-width: 768px) {
    /*margin: -20px -50px 0;*/
    padding: 5px 0 45px;
  }

  :global .active {
    color: #57A50B;
    font-weight: 700;
    position: relative;

    &:after {
      content: "";
      background: #57A50B;
      display: inline-block;
      width: 100%;
      height: 4px;
      position: absolute;
      left: 0;
      bottom: 0;

      @media (min-width: 768px) {
        border-radius: 10px;
      }
    }
  }
}

.nav a {
  color: #45555F;
  display: inline-block;
  font-family: Montserrat;
  font-size: 13px;
  font-weight: 300;
  letter-spacing: .8px;
  padding: 0 10px 10px;
  text-decoration: none;
  text-transform: uppercase;
  width: 45%;

  @media (min-width: 768px) {
    padding: 0 20px 20px;
  }

  @media (min-width: 768px) {
    font-size: 16px;
    margin: 0 10px;
    padding: 0 0 20px;
    min-width: 200px;
  }
}
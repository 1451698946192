.root {
  display: block;
  width: 64px;
  height: 64px;
  margin: 0 0 20px;

  span {
    content: " ";
    display: block;
    width: 46px;
    height: 46px;
    margin: 1px;
    border-radius: 50%;
    border: 5px solid #2479b3;
    border-color: #2479b3 transparent #2479b3 transparent;
    animation: spinner 1.2s linear infinite;
  }
}

.rootSmall {
  display: inline-block;
  width: 24px;
  height: 24px;

  span {
    content: " ";
    display: block;
    width: 20px;
    height: 20px;
    margin: 2px;
    border-radius: 50%;
    border: 2px solid #2479b3;
    border-color: #2479b3 transparent #2479b3 transparent;
    animation: spinner 1.2s linear infinite;
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

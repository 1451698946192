.root {
  background: #2479b3;
  border-radius: 100%;
  display: inline-block;
  width: 26px;
  height: 26px;
  position: absolute;
  top: 40px;
  right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform-origin: 50%;

  &:hover {
    background-color: lighten(#2479b3, 3%);
  }

  &:active {
    background-color: darken(#2479b3, 3%);
  }

  @media (min-width: 768px) {
    width: 50px;
    height: 50px;
    top: calc(50% - 25px);
    right: 40px;
  }

  &:after {
    background: url(../../assets/chevron-right-white.svg) 50%/100% auto
      no-repeat;
    content: "";
    display: inline-block;
    width: 7px;
    height: 11px;

    @media (min-width: 768px) {
      width: 11px;
      height: 18px;
    }
  }
}

.wrapper {
  background: #57a50b;
  width: 100%;
  position: fixed;
  z-index: 10;
  padding: 10px;
  height: 100vh;
}

.root {
  text-align: center;
  background: #fff;
  padding: 1px;
  height: calc(100vh - 45px);
  border-radius: 5px;

  h1 {
    background: url(../assets/operation-done.svg) 50% 0 no-repeat;
    color: #57a50b;
    font: 400 32px/0.75 "Roboto", sans-serif;
    margin: 0 0 10px;
    letter-spacing: 0.34px;
    padding-top: 180px;
  }

  .date {
    color: #7a868d;
    display: block;
    line-height: 1.5;
    margin: 0 0 25px;
  }
}

.content {
  max-width: 430px;
  margin: 50px auto;

  > div {
    display: flex;
    margin: 0 0 45px;

    div {
      min-height: 120px;
      padding: 25px;
      width: 50%;

      + div {
        border-left: 1px solid rgba(#c2c6ca, 0.5);
      }
    }
  }
}

.title {
  color: #7a868d;
  display: block;
  font: 300 13px/1.85 "Montserrat", sans-serif;
  margin: 0 0 10px;
  text-transform: uppercase;
}

.number {
  color: #45555f;
  font: 700 32px/0.75 "Roboto", sans-serif;
  letter-spacing: -0.9px;
}

.time {
  margin: 0 auto;
}

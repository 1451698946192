.root {
  background: url(../routes/assets/notice.svg) 50% 25px/30px 30px no-repeat;
  border-left: 10px solid #F26161;
  box-sizing: border-box;
  margin: 20px;
  padding: 70px 20px 20px;

  @media (max-width: 767px) {
    pre {
      max-width: calc(100vw - 100px);
      white-space: normal;
      word-wrap: break-word;
    }
  }

  @media (min-width: 768px) {
    padding: 70px 40px 20px;
  }
}
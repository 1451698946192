.panel {
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 5px;
}

.disabledPanel {
  opacity: 0.5;
  pointer-events: none;
}

.nameClarificationLabel {
  margin-right: 20px;
}

.dataHeader {
  font-size: 14px;
  color: rgba(#45555f, 0.7);
  padding: 20px;

  @media (max-width: 1023px) {
    display: none;
  }
}

.dataHeader span {
  display: block;

  @media (min-width: 1024px) {
    display: inline-block;
  }
}

@mixin dataFieldWidth($width) {
  width: 100% * $width/700;
}

@mixin dataFieldBefore {
  color: rgba(#45555f, 0.7);
  display: inline-block;
  font-size: 14px;
  font-weight: normal;
  vertical-align: middle;
  width: 80px;
  margin-top: -4px;
}

.dataProductName {
  @media (max-width: 1023px) {
    &:before {
      content: "Tuotenimi";
      @include dataFieldBefore();
    }
  }
  @media (min-width: 1024px) {
    @include dataFieldWidth(200);
  }
}

.dataAmountLoaded {
  @media (max-width: 1023px) {
    &:before {
      content: "Toteutunut";
      @include dataFieldBefore();
    }
  }
  @media (min-width: 1024px) {
    @include dataFieldWidth(100);
  }
}

.dataAmount {
  @media (max-width: 1023px) {
    &:before {
      content: "Tilattu";
      @include dataFieldBefore();
    }
  }
  @media (min-width: 1024px) {
    @include dataFieldWidth(100);
  }
}

.dataWeight {
  @media (max-width: 1023px) {
    &:before {
      content: "Kilot";
      @include dataFieldBefore();
    }
  }
  @media (min-width: 1024px) {
    @include dataFieldWidth(100);
  }
}

.dataItems {
  @media (max-width: 1023px) {
    &:before {
      content: "Kollit";
      @include dataFieldBefore();
    }
  }
  @media (min-width: 1024px) {
    @include dataFieldWidth(80);
  }
}

.dataDimensions {
  @media (max-width: 1023px) {
    &:before {
      content: "Mitat";
      @include dataFieldBefore();
    }
  }
  @media (min-width: 1024px) {
    @include dataFieldWidth(110);
  }
}

.dataProductName {
  background: url(../../assets/cargo--sm.svg) 0 50%/18px 18px no-repeat;
  padding-left: 35px;
}

.consolidatedSignature {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}